import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserControllerService, UserDto } from 'app/api';
import { User } from 'app/core/user/user.types';
import { StorageService } from 'app/services/storage/storage.service';
import { map, Observable, ReplaySubject, tap } from 'rxjs';
import { RoleService } from '../role-management/role-management.service';
import { OrganisationFilterService } from 'app/layout/common/organisation-filter/organisation-filter.service';
import { TypeService } from '../type-management/type-management.service';

@Injectable({ providedIn: 'root' })
export class UserService {
    private _user$: ReplaySubject<User> = new ReplaySubject<User>(1);
    private _user: User;

    private _userFromTable: UserDto;

    public get userFromTable(): UserDto {
        return this._userFromTable;
    }

    private _checkinId: ReplaySubject<string> = new ReplaySubject<string>(1);

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private storageService: StorageService,
        private userController: UserControllerService,
        private typeService: TypeService,
        private roleService: RoleService
    ) {
        const subscription = this._user$.subscribe(user$ => {
            if (user$) {
                this.userController.getUser1(user$.keycloakId).subscribe(user => {
                    this.roleService.userRoles = [user.authorizations];
                    subscription.unsubscribe();

                    this._userFromTable = user;
                    const _user = { ...this.user };
                    _user.metadata = user.metadata;
                    _user.firstname = user.firstName;
                    _user.lastname = user.lastName;
                    _user.email = user.email;

                    this.user = _user;
                });
            }
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: User) {
        // Store the value
        this._user$.next(value);
        this._user = value;
        if (value && value.userType) {
            this.typeService.userType = value.userType;
        }
    }

    get user() {
        return this._user;
    }

    get user$(): Observable<User> {
        return this._user$.asObservable();
    }

    /**
     * Setter & getter for checkIn id
     *
     * @param value
     */
    set id(value: string) {
        // Store the value
        this.checkInId = value;
        this._checkinId.next(value);
    }

    get id$(): Observable<string> {
        return this._checkinId.asObservable();
    }

    get checkInId() {
        return this.storageService.getId();
        // return localStorage.getItem('id');
    }
    set checkInId(id: string) {
        this.storageService.setId(id); //localStorage.setItem('id', id);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current logged in user data
     */
    get(): Observable<User> {
        return this._httpClient.get<User>('api/common/user').pipe(
            tap(user => {
                this._user$.next(user);
            })
        );
    }

    /**
     * Update the user
     *
     * @param user
     */
    update(user: User): Observable<any> {
        return this.userController.updateUser(user.keycloakId, {
            firstName: user.firstname,
            lastName: user.lastname,
            email: user.email,
            username: user.id,
            metadata: user.metadata,
            organisations: this.userFromTable.organisations,
        });
    }
}
