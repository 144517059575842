import { Injectable, Signal, WritableSignal, signal } from '@angular/core';
import { ConfirmationService } from 'app/services/confirmation/confirmation.service';
import { UserType } from './user.type';
import { StorageService } from 'app/services/storage/storage.service';

@Injectable({
    providedIn: 'root',
})
export class TypeService {
    private _userType: WritableSignal<UserType> = signal(null);

    public get userType(): Signal<UserType> {
        return this._userType.asReadonly();
    }
    public set userType(value: UserType) {
        const clientId = this.storageService.getClientId();
        if (clientId === 'subscriptions') {
            this._userType.set(UserType.SUBSCRIPTION);
        } else {
            this._userType.set(UserType.CORPORATE);
        }
        // this._userType.set(value);
    }

    constructor(
        private dialogService: ConfirmationService,
        private storageService: StorageService
    ) {
        const clientId = this.storageService.getClientId();
        if (clientId === 'subscriptions') {
            this._userType.set(UserType.SUBSCRIPTION);
        } else {
            this._userType.set(UserType.CORPORATE);
        }
    }

    supportedPlans(plans: UserType[]): boolean {
        return plans.includes(this.userType());
    }

    openInsufficientPermissionsDialog(): void {
        this.dialogService.open({
            title: 'insufficientPermissionTitle',
            message: 'insufficientPermissionDescription',
            dismissible: true,
            actions: {
                confirm: {
                    show: false,
                },
            },
        });
    }
}
