import { inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { merge } from 'lodash-es';
import { ConfirmationConfig } from './confirmation.types';
import { ConfirmationDialogComponent } from './dialog/dialog.component';

export type ConfirmationCloseAction = 'confirmed' | 'cancelled' | undefined;

@Injectable({ providedIn: 'root' })
export class ConfirmationService {
    private _matDialog: MatDialog = inject(MatDialog);
    private _defaultConfig: ConfirmationConfig = {
        title: 'title',
        message: 'message',
        icon: {
            show: true,
            name: 'heroicons_outline:exclamation-triangle',
            color: 'warn',
        },
        actions: {
            confirm: {
                show: true,
                label: 'confirm',
                color: 'warn',
            },
            cancel: {
                show: true,
                label: 'cancel',
            },
        },
        dismissible: true,
    };

    /**
     * Constructor
     */
    constructor() {}

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    open(config: ConfirmationConfig = {}): MatDialogRef<ConfirmationDialogComponent> {
        // Merge the user config with the default config
        const userConfig = merge({}, this._defaultConfig, config);

        // Open the dialog
        return this._matDialog.open(ConfirmationDialogComponent, {
            autoFocus: false,
            disableClose: !userConfig.dismissible,
            data: userConfig,
            panelClass: 'confirmation-dialog-panel',
        });
    }
}
