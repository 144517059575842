import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
    providedIn: 'root',
})
export class StorageService {
    private secretKey = '147258369';

    constructor() {}

    setClientId(clientId: string): void {
        const encryptedClientId = CryptoJS.AES.encrypt(clientId, this.secretKey).toString();
        localStorage.setItem('clientId', encryptedClientId);
    }

    getClientId(): string | null {
        const encryptedClientId = localStorage.getItem('clientId');
        if (encryptedClientId) {
            const bytes = CryptoJS.AES.decrypt(encryptedClientId, this.secretKey);
            return bytes.toString(CryptoJS.enc.Utf8);
        }
        return null;
    }

    setId(clientId: string): void {
        const encryptedClientId = CryptoJS.AES.encrypt(clientId, this.secretKey).toString();
        localStorage.setItem('id', encryptedClientId);
    }

    getId(): string | null {
        const encryptedClientId = localStorage.getItem('id');
        if (encryptedClientId) {
            const bytes = CryptoJS.AES.decrypt(encryptedClientId, this.secretKey);
            return bytes.toString(CryptoJS.enc.Utf8);
        }
        return null;
    }
}
