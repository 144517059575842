/**
 * Process API
 * API for managing processes.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface MetadataDescriptorFieldsInner { 
    /**
     * The name of the property
     */
    propertyName: string;
    /**
     * The label of the field
     */
    label: string;
    /**
     * The placeholder of the field
     */
    placeholder?: string;
    /**
     * The type of the property, string, number - text field, boolean - check box, array - select
     */
    fieldType: MetadataDescriptorFieldsInner.FieldTypeEnum;
    /**
     * Options for select
     */
    options?: Array<string>;
    /**
     * Default value
     */
    defaultValue?: string;
}
export namespace MetadataDescriptorFieldsInner {
    export type FieldTypeEnum = 'string' | 'number' | 'boolean' | 'array';
    export const FieldTypeEnum = {
        String: 'string' as FieldTypeEnum,
        Number: 'number' as FieldTypeEnum,
        Boolean: 'boolean' as FieldTypeEnum,
        Array: 'array' as FieldTypeEnum
    };
}


