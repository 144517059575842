/**
 * Process API
 * API for managing processes.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type BusinessProcessCategory = 'process' | 'decission' | 'integrationTask';

export const BusinessProcessCategory = {
    Process: 'process' as BusinessProcessCategory,
    Decission: 'decission' as BusinessProcessCategory,
    IntegrationTask: 'integrationTask' as BusinessProcessCategory
};

