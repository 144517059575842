/**
 * Process API
 * API for managing processes.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type BusinessProcessTaskActionCategory = 'PROCESS' | 'CONTROL' | 'M_PROCESS' | 'SAP_TASK' | 'S3_COPY_FILE' | 'S3_READ_FILE' | 'S3_FILE_TO_DB';

export const BusinessProcessTaskActionCategory = {
    Process: 'PROCESS' as BusinessProcessTaskActionCategory,
    Control: 'CONTROL' as BusinessProcessTaskActionCategory,
    MProcess: 'M_PROCESS' as BusinessProcessTaskActionCategory,
    SapTask: 'SAP_TASK' as BusinessProcessTaskActionCategory,
    S3CopyFile: 'S3_COPY_FILE' as BusinessProcessTaskActionCategory,
    S3ReadFile: 'S3_READ_FILE' as BusinessProcessTaskActionCategory,
    S3FileToDb: 'S3_FILE_TO_DB' as BusinessProcessTaskActionCategory
};

