/**
 * Process API
 * API for managing processes.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface RouteItemMetaData { 
    name?: string;
    description?: string;
    source?: RouteItemMetaData.SourceEnum;
    category?: RouteItemMetaData.CategoryEnum;
}
export namespace RouteItemMetaData {
    export type SourceEnum = 'INPUT' | 'OUTPUT';
    export const SourceEnum = {
        Input: 'INPUT' as SourceEnum,
        Output: 'OUTPUT' as SourceEnum
    };
    export type CategoryEnum = 'DOCUMENT' | 'FILE' | 'TABLE' | 'M_DOCUMENT' | 'M_FILE' | 'M_TABLE' | 'INTEGRATION_TASK_PARAM';
    export const CategoryEnum = {
        Document: 'DOCUMENT' as CategoryEnum,
        File: 'FILE' as CategoryEnum,
        Table: 'TABLE' as CategoryEnum,
        MDocument: 'M_DOCUMENT' as CategoryEnum,
        MFile: 'M_FILE' as CategoryEnum,
        MTable: 'M_TABLE' as CategoryEnum,
        IntegrationTaskParam: 'INTEGRATION_TASK_PARAM' as CategoryEnum
    };
}


