/**
 * Process API
 * API for managing processes.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface TestScenario { 
    id?: string;
    bp_id?: string;
    description?: string;
    testObjective?: string;
    prerequisites?: string;
    priority?: TestScenario.PriorityEnum;
    businessProcess?: string;
    functionalArea?: string;
    usage?: string;
    wbs?: string;
    module?: string;
    coveringEntryPoints?: number;
    system?: string;
    countryOrRegion?: string;
    dueDate?: string;
    workEffort?: number;
    timeToComplete?: number;
}
export namespace TestScenario {
    export type PriorityEnum = 'Low' | 'Medium' | 'High';
    export const PriorityEnum = {
        Low: 'Low' as PriorityEnum,
        Medium: 'Medium' as PriorityEnum,
        High: 'High' as PriorityEnum
    };
}


