/**
 * Process API
 * API for managing processes.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ObjectExplorerItemType = 'process' | 'decision' | 'entry' | 'organisation' | 'system' | 'area';

export const ObjectExplorerItemType = {
    Process: 'process' as ObjectExplorerItemType,
    Decision: 'decision' as ObjectExplorerItemType,
    Entry: 'entry' as ObjectExplorerItemType,
    Organisation: 'organisation' as ObjectExplorerItemType,
    System: 'system' as ObjectExplorerItemType,
    Area: 'area' as ObjectExplorerItemType
};

